
@charset "UTF-8";
.tab {
  background-color: #d2d2d2;
  display: block;
  width: 212px;
  height: 70px;
  float: left;
  border: none;
  border-bottom: 2px solid #cacaca;
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
  letter-spacing: 2px;
}
.tab:last-child {
    margin-right: 0;
    float: right;
}
.tab.active {
    background-color: #ededed;
    border-color: #ededed;
    color: #767676;
}
.tab1 {
    padding-left: 45px;
    padding-right: 45px;
}
.contactForm {
  background-color: #eee;
  padding-top: 35px;
  padding-bottom: 60px;
}
.formColum {
  padding-top: 25px;
  padding-bottom: 26px;
  margin: auto;
  background-color: #eee;
  display: table;
  width: 819px;
  text-align: left;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 4.8px;
  border-bottom: 1px dotted #5c5c5c;
}
.formColumLast {
    border-bottom: none;
    margin-bottom: 23px;
}
.formColum1 {
    display: table-cell;
    width: 193px;
    padding-top: 3px;
}
.formColum2 {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
}
.formInput {
  width: 100%;
  height: 39px;
  padding: 0 10px;
  border-radius: 5px;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 2.43;
  letter-spacing: 0.7px;
}
.formInputSmall {
    width: 116px;
}
.formInputSen {
    font-size: 14px;
    margin-right: 6px;
    margin-left: 10px;
}
.formTextarea {
  height: 263px;
}
.formSelect {
  appearance: button;
  -webkit-appearance: button;
  -moz-appearance: button;
  width: 100%;
  position: relative;
  z-index: 1;
  background: none transparent;
  height: 38px;
  padding: 0 10px;
  border: none;
  font-size: 14px;
}
.formBtn {
  width: 218px;
  height: 65px;
  border-radius: 2px;
  margin: auto;
  line-height: 1.62;
  letter-spacing: 2.4px;
}
.formBtnWrap {
    width: 450px;
    margin: auto;
}
.formMailFS {
  font-size: 14px;
}
.selectWrap {
  width: 100%;
  position: relative;
  border-radius: 5px;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
}
.selectWrap::before {
    content: "\25BC";
    position: absolute;
    right: 10px;
    top: 2px;
    z-index: 0;
    font-size: 10px;
}
.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.required {
  position: relative;
  top: -5px;
  font-size: 12px;
}
.borderRed {
  border-color: red;
}
@media (max-width: 767px) {
.tabWrap {
    display: none;
}
.tabWrapSP {
      width: 83%;
      margin: auto;
      margin-bottom: 20px;
}
.tabWrapSP.selectWrap:before {
        top: 0;
        right: 7px;
}
.formColum {
    font-size: 14px;
    width: 100%;
    display: block;
    padding-bottom: 20px;
    padding-top: 3px;
}
.formColum1 {
      display: block;
      margin-bottom: 5px;
}
.formColum2 {
      display: block;
}
.formColumLast {
      margin-bottom: 8px;
}
.formBtn {
    width: 100%;
    height: 47px;
    line-height: 43px;
    letter-spacing: 3.8px;
    font-size: 13px;
}
.formBtnWrap {
      width: 100%;
}
.formInput {
    height: 35px;
}
.formInputSmall {
      width: 87px;
      margin-bottom: 20px;
}
.formInputSmall2 {
        width: 81px;
        margin-bottom: 0;
}
.formInputSen {
      margin-right: -8px;
      margin-left: -4px;
}
.formSelect {
    height: 34px;
}
.formMailFS {
    font-size: 12px;
}
.formTextarea {
    height: 155px;
}
.select {
    height: 33px;
}
.selectWrap {
      height: 34px;
}
.selectWrap:before {
        right: 6px;
        top: -1px;
}
.contactForm {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}
.mb20sp {
    margin-bottom: 20px !important;
}
.pb15sp {
    padding-bottom: 15px !important;
}
}
